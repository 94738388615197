<app-navbar></app-navbar>

<style>
    .colorAdd{
        color: red;
    }
    .banner_content_
    {
        color:#080809
    }
    
</style>
<head>
    <title> TrueCheck</title>
<meta name="keywords" content="TrueCheck,Automated,Underwriting,Onboarding,Customer Consent,Live Signature,KYC,Digi KYC,Onboarding,Cross Kyc, Automation, Fraud Prevention, Live photo, Live Signature, Live Sign, Protect, Content, Customer Consent" />
</head>

<!-- Start Identity  Banner Area -->
<div class="bd-banner-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-banner-contents">
                    <kbd class="NewLaunchLabel">  NEW LAUNCH  </kbd>
                    <!-- <kbd  class="NewLaunchLabel">  NEW LAUNCH  </kbd>
                  <h1 style="margin-top: 11px; font-size: 50px; color: #0f33e0;">TrueCheck</h1> -->
                  <div style="display: flex; align-items: center;">
                    <h1 style="font-size: 50px; color: red; margin: 0;">TrueCheck</h1>
                    <!-- <kbd class="NewLaunchLabel"><span>NEW LAUNCH</span></kbd> -->
                    
                </div>
                
                  <h3 style="color: #e23636;">Identity Verification & Fraud Prevention Platform</h3>
                    <p class="banner_content_"><i class="fa-solid fa-check colorAdd"></i>&nbsp; TrueCheck refers to the process of managing and securing digital identities. It involves authenticating users, controlling access to resources, and ensuring data privacy and security.</p>
                    <p class="banner_content_"><i class="fa-solid fa-check colorAdd"></i>&nbsp; Protect your digital environment with built-in spam prevention and auto-classification tools, which simplify form completion and ensure data integrity.</p>
                    <p class="banner_content_"><i class="fa-solid fa-check colorAdd"></i>&nbsp; Apply multi-factor authentication (MFA) to enhance security with additional identity verification layers, such as passwords and biometrics.</p>

                            <!-- <a class="default-btn burger-menu">
                                Get Started
                            </a> -->
                            <!-- <a style="background-color: red;" class="default-btn" href="https://truecheck.ai/">Visit Our Website</a> -->
                            <div>
                            <a href="https://truecheck.ai/" target="_blank" class="default-btn mt-4">
                                Visit Our Website<span></span>
                            </a>
                            </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="bd-banner-image">
                    <img style="height: 512px; width: 491px;" src="assets/img/Identity_Management/trucheck_banner.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image" title="x-Biz Ventures | Identity Verification | Identity Management | Onboarding | DigiVision(digivision.ai)">

                    <div class="circle-shape"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End  Banner Area -->


<!-- Start  About Area -->
<div class="bd-about-area">
    <div class="container" title="KYC | Digi KYC | Onboarding | Cross Kyc | Automation | Fraud Prevention | Live photo | Live Signature | Live Sign | Protect | Content | Customer Consent">
        <div class="row align-items-center"><div class="col-lg-12"><div class="section-title with-line-text-with-white-color"><span class="sub-title text-center">ABOUT TrueCheck</span>
            <h3>Effortlessly protect, handle and simplify user identities with our all-inclusive Identity Verification system.</h3>
            <p>We partner with industry experts to reshape digital interactions and unleash the full power of identity Verification.</p>
        </div></div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-about-image">
                    <img style="width: 580px;" src="assets/img/Identity_Management/about_truecheck.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-about-content">
                    
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 55px; margin-top: 6px;" src="assets/img/Identity_Management/fraud.png" alt="image">
                        </div>
                        <h4>Fraud Prevention</h4>
                        
                        <p>Inbuilt Spam protection, Fraud prevention involves implementing measures and strategies to detect, deter and mitigate fraudulent activities or tampering with KYC documents while auto onboarding within a system or organization. </p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 48px;" src="assets/img/Identity_Management/CrossKYc.png" alt="image">
                        </div>
                        <h4>Cross KYC Data</h4>
                        <p> DigiVision AI Powered document automation system, Cross KYC data enables sharing verified customer information between entities, streamlining verification processes and reducing duplication.</p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 48px;" src="assets/img/Identity_Management/Visual Profiling.png" alt="image">
                        </div>
                        <h4> Visual Profiling (Verification) </h4>
                        <p>  Livlines check and customer verification via Video calling, OKYC and Document KYC provide Demographic details and visaul indentity. such as Prefilled Identity form, live Video, photo, Sign enables real-time identity acquisition using multiple device devices like smartphones, webcams, Laptop or PC facilitating instant authentication, authorization,  sharing and communication.</p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 51px; margin-left: 11px;" src="assets/img/Identity_Management/Customer Consent.png" alt="image">
                        </div>
                        <h4>Customer Consent</h4>
                        <p>Customer Consent refers to the extent to which a company's revenue is reliant on a small number of customers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</div>
<!-- End BD About Area -->








<!-- Ml Services Area -->
<section class="ml-services-area bg-f2f2f7 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>Our Professionals Services For You</h2>
            <p class="d-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/Identity_Management/spam.png" alt="image">
                    </div>
                    <h3 style="text-align: center;">Spam Protection</h3>
                    <p style="text-align: center;">Spam protection encompasses various techniques aimed at filtering out unsolicited or unwanted emails, messages or content.</p>
                   
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/Identity_Management/Onboarding_img.png" alt="image">
                    </div>
                    <h3 style="text-align: center;">Onboarding</h3>
                    <p style="text-align: center;">Onboarding refers to the process of integrating new Employees/ Customer,it is hasel free, powerful AI automation tool This is Automated Data entry, mitigate fraud and comply with regulation.</p>
                    
                </div>
            </div>


            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img style="height: 180px; width: 180px;" src="assets/img/Identity_Management/Verification.png" alt="image">
                    </div>
                    <h3 style="text-align: center;">Verification</h3>
                    <p style="text-align: center;">Verification offers cutting-edge offline solutions to automate and review Onboarding journey, OKYC/ Video KYC/ RE-KYC revolutionizing End-to-endd document Verification processes.</p>
                 
                </div>
            </div>

           

            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img style="height: 180px; width: 180px;" src="assets/img/Identity_Management/user_authentication.png" alt="image">
                    </div>
                    <h3 style="text-align: center;">User Authentication</h3>
                    <p style="text-align: center;">User authentication is the process of verifying the identity of an individual accessing a system, typically through credentials like usernames and passwords.</p>
                    
                </div>
            </div>

           
            
           
        </div>
    </div>
</section>


<!-- Start why Choose Identity Area -->
<div class="bd-choose-area ptb-100">
    <div class="container" style="margin-top: -4rem;">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-content">
                    <h3 style="color: red">IDENTITY VERIFICATION PLATFORM</h3>
                    <p>Our Identity Verification solution provides comprehensive features and benefits to enhance your organization's security and efficiency.</p>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> Select KYC</li>
                                <li><i class="fa-solid fa-check"></i> Identity Details</li>                              
                                <li><i class="fa-solid fa-check"></i> Identify Yourself</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> Add KYC Document</li>
                                <li><i class="fa-solid fa-check"></i> Manage Type and Complete</li>
                                <li><i class="fa-solid fa-check"></i> Audit Trails</li>
                                
                            </ul>
                        </div>
                    </div>
                  
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-image">
                    <img style="height: 533px;width: 527px;margin-left: 15px;" src="assets/img/Identity_Management/Verification_Platform.svg
                    " alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End BD Choose Area -->

<div class="bd-overview-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="bd-overview-content">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img style="height: 40px;" src="assets/img/Identity_Management/CrossKYc.png" alt="image">
                        </div>
                        <h5>Document KYC</h5>
                        <p>Document KYC involves collecting and verifying specific documents to confirm a customer's identity and relevant information.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img style="height: 50px;" src="assets/img/big-data-home/overview/customer_onboarding.png" alt="image">
                        </div>
                        <h5>Customer Onboarding</h5>
                        <p>Improve insurance customer onboarding by simplifying data collection and verification processes for a smoother enrollment experience.</p>
                    </div>
                </div>  
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card" style="height: 380px;">
                        <div class="overview-image">
                            <img style="height: 50px;" src="assets/img/big-data-home/overview/ACO.png" alt="image">
                        </div>
                        <h5>Account Opening</h5>
                        <p>Streamline account opening and ensure regulatory compliance by automating KYC procedures efficiently.</p>
                    </div>
                </div>
                
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card" style="height: 380px;">
                        <div class="overview-image">
                            <img style="height: 40px;" src="assets/img/big-data-home/overview/Auto_under.png" alt="image">
                        </div>
                        <h5 style="margin-top: -11px;">Automated Underwriting</h5>
                        <p>
                            AI-driven underwriting automation using BRE rules ensures rapid, informed decision-making for underwriters by providing comprehensive risk visibility.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start FAQ Area -->
<section class="faq-area ptb-100 d-none">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">FAQ</span>
            <h2>Frequently Asked Questions</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-img pr-3">
                    <img src="assets/img/faq.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
           
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Can I have multiple activities in a single feature?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                How to get start with us?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Subscribe Area -->
<section class="free-trial-area ptb-100 bg-f4f7fe d-none">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="ft-img">
                    <img src="assets/img/machine-learning/free-trial-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="subscribe-content">
                    <span class="sub-title">Get Started Instantly!</span>
                    <h2>Start your free trial</h2>

                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="email">
                        <button type="submit">Sign Up Free</button>
                    </form>

                    <p class="help-text">Test out the Machine Learning features for 14 days, no credit card required.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="d-flex justify-content-center mt-5">
        <div class="h-50"><h1>READY TO TAKE NEXT STEP? </h1></div>
     </div>
     <div class="d-flex justify-content-center mb-5">
        <div class="banner-btn">
            <a class="default-btn me-4 burger-menu">
                CONTACT SALES <span></span>
            </a>
        </div>
     </div>
</div>

<!-- End Subscribe Area -->
<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Digidrive" ><i class="flaticon-left-chevron"></i> Digi-Drive DMS </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Redaction-Solution">PII Masking <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>

