import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { DigioneComponent } from './components/pages/digi-one/digi-one.component';
import { DigitwoComponent } from './components/pages/digi-two/digi-two.component';
import { DigithreeComponent } from './components/pages/digi-three/digi-three.component';
import { DigifourComponent } from './components/pages/digi-four/digi-four.component';
import { DigifiveComponent } from './components/pages/digi-five/digi-five.component';
import { DigisixComponent } from './components/pages/digi-six/digi-six.component';
import { DigisevenComponent } from './components/pages/digi-seven/digi-seven.component';
import { DigieightComponent } from './components/pages/digi-eight/digi-eight.component';
import { DiginineComponent } from './components/pages/digi-nine/digi-nine.component';
import { DigitenComponent } from './components/pages/digi-ten/digi-ten.component';
import { DigielevenComponent } from './components/pages/digi-eleven/digi-eleven.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PartnerComponent } from './components/pages/partner/partner.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { HomeDemoFourComponent } from './components/pages/home-demo-four/home-demo-four.component';
import { HomeDemoFiveComponent } from './components/pages/home-demo-five/home-demo-five.component';
import { HomeDemoSixComponent } from './components/pages/home-demo-six/home-demo-six.component';
import { HomeDemoSevenComponent } from './components/pages/home-demo-seven/home-demo-seven.component';
import { HomeDemoEightComponent } from './components/pages/home-demo-eight/home-demo-eight.component';
import { HomeDemoNineComponent } from './components/pages/home-demo-nine/home-demo-nine.component';
import { AboutStyleTwoComponent } from './components/pages/about-style-two/about-style-two.component';
import { TeamStyleTwoComponent } from './components/pages/team-style-two/team-style-two.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { ProjectsStyleTwoComponent } from './components/pages/projects-style-two/projects-style-two.component';
import { ProjectsStyleThreeComponent } from './components/pages/projects-style-three/projects-style-three.component';
import { ProjectsStyleFourComponent } from './components/pages/projects-style-four/projects-style-four.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { HomeDemoTenComponent } from './components/pages/home-demo-ten/home-demo-ten.component';
import { HomeDemoElevenComponent } from './components/pages/home-demo-eleven/home-demo-eleven.component';
import { HomeDemoTwelveComponent } from './components/pages/home-demo-twelve/home-demo-twelve.component';
import { HomeDemoThirteenComponent } from './components/pages/home-demo-thirteen/home-demo-thirteen.component';
import { Register2Component } from './components/pages/register2/register2.component';
import { Register3Component } from './components/pages/register3/register3.component';
import { ThankyouComponent } from './components/pages/thankyou/thankyou.component';
import { HomeDemoFifteenComponent } from './components/pages/home-demo-fifteen/home-demo-fifteen.component';
import { InnovationLabComponent } from './components/pages/innovation-lab/innovation-lab.component';

const routes: Routes = [
    {path: '', component: HomeDemoOneComponent},
    {path: 'index-2', component: HomeDemoTwoComponent},
    {path: 'index-3', component: HomeDemoThreeComponent},
    {path: 'index-4', component: HomeDemoFourComponent},
    {path: 'index-5', component: HomeDemoFiveComponent},
    {path: 'index-6', component: HomeDemoSixComponent},
    {path: 'index-7', component: HomeDemoSevenComponent},
    {path: 'Digivision', component: HomeDemoEightComponent},
    {path: 'SecureNexus-GRC', component: HomeDemoNineComponent},
    {path: 'index-10', component: HomeDemoTenComponent},
    {path: 'SecureNexus-ISS', component: HomeDemoElevenComponent},
    {path: 'Digidrive', component: HomeDemoTwelveComponent},
    {path: 'about', component: AboutComponent},
    {path: 'AboutUs', component: AboutStyleTwoComponent},
    {path: 'Banking', component: ServicesComponent},
    {path: 'Insurance', component: ServicesStyleTwoComponent},
    {path: 'Healthcare', component: ServicesStyleThreeComponent},
    {path: 'Tele-com', component: ServiceDetailsComponent},
    {path: 'EKYC', component: DigioneComponent},
    {path: 'Offline-Vision-Engine', component: DigitwoComponent},
    {path: 'Video-KYC', component: DigithreeComponent},
    {path: 'Document-Indexing', component: DigifourComponent},
    {path: 'Document-Detection', component: DigifiveComponent},
    {path: 'Identity-Management', component: DigisixComponent},
    {path: 'Redaction-Solution', component: DigisevenComponent},
    {path: 'Compression-Solution', component: DigieightComponent},
    {path: 'Location-Based-Solution', component: DiginineComponent},
    {path: 'Object-Detection-Solution', component: DigitenComponent},
    {path: 'iMagica-Suite', component: DigielevenComponent},
    {path: 'projects-2', component: ProjectsStyleTwoComponent},
    {path: 'projects-3', component: ProjectsStyleThreeComponent},
    {path: 'Blogs', component: ProjectsStyleFourComponent},
    {path: 'Blog-Details', component: ProjectDetailsComponent},
    {path: 'Press-Release', component: BlogComponent},
    {path: 'Career', component: BlogRightSidebarComponent},
    {path: 'career-details', component: BlogDetailsComponent},
    {path: 'features', component: FeaturesComponent},
    {path: 'team', component: TeamComponent},
    {path: 'team-2', component: TeamStyleTwoComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'partner', component: PartnerComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'sitemap', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'register2', component: Register2Component},
    {path: 'register3', component: Register3Component},
    {path: 'contact', component: ContactComponent},
    {path: 'thank-you', component: ThankyouComponent},
    {path:'TrueCheck',component:HomeDemoThirteenComponent},
    {path:'TrustTier',component:HomeDemoFifteenComponent},
    {path:'Innovation',component:InnovationLabComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }