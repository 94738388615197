<app-navbar></app-navbar>

<div class="saas-banner-two hero-banner banner-bg2 ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-50">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1><span class="highlight">Innovating</span> for Your Success<span class="highlight"></span> </h1>
                            <p>At our Innovation Lab, we transform cutting-edge ideas into impactful digital solutions, driving business growth through technology experimentation and rapid prototyping. Our team pushes the boundaries of innovation, delivering breakthrough advancements tailored to your needs </p>
                            
                            <!-- <div class="banner-btn">
                                <a class="default-btn-two">
                                    Try It Now <span></span>
                                </a>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-6">
                       <div class="saas-banner-image animate-tb">
                            <img src="assets\img\Innovation_Lab\Visionary technology-amico 1.png" alt="image" style="width: 90%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  -->
<section class="pricing-area-two pt-100 pb-70  ">
    <div class="container">
        <div class="section-title" style="max-width: 800px !important;">
            <h2 class=" text-center" style="max-width: 800px !important;">Emerging & Advanced Technologies</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-12 col-sm-12"> <div class="single-pricing-table text-left">
                <img src="assets/img/Innovation_Lab/devlop.jpg" alt="New Technology Image" style="width: 100%; height: auto;">
            </div></div>
            <div class="col-lg-4 col-sm-12">
                <div class="single-pricing-table text-left"style="background-color: #f4f7fc;border: 1px solid #c9cbd0;min-height:410px;">
                    <ul class="pricing-features" style="margin: 0;">
                        <li>
                            <img src="assets\img\Innovation_Lab\Tele.png" alt="Telematics Icon" style="width:20px; height:20px;">
                            Telematics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\IOT.png" alt="IoT Icon" style="width:20px; height:20px;">
                            IoT
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\scanner.png" alt="Scanner Icon" style="width:20px; height:20px;">
                            Scanner
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Stack.png" alt="Scanner Icon" style="width:20px; height:20px;"> 
                            India Stack
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\qr-code.png" alt="QR Code Icon" style="width:20px; height:20px;">
                            QR code
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\biometrics.png" alt="Biometrics Icon" style="width:20px; height:20px;">
                            Biometrics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Drone_imagining.png" alt="Drone Imaging CCTV Icon" style="width:20px; height:20px;">
                            Drone Imaging
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Geo_Tagging.png" alt="Drone Imaging CCTV Icon" style="width:20px; height:20px;">
                           Geo-Tagging
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\cctv-camera.png" alt="CCTV and Custom Web Apps Icon" style="width:20px; height:20px;">
                            CCTV 
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Custome_mobileapps .png" alt="CCTV Icon" style="width:20px; height:20px;">
                            Custom Web & Mobile Apps
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Custom_camera.png" alt="Custom Web & Mobile Apps Icon" style="width:20px; height:20px;">
                            Custom Camera
                        </li>
                    </ul>
                </div>
            </div>
        
            <div class="col-lg-4 col-sm-12">
                <div class="single-pricing-table text-left" style="background-color: #f4f7fc;border: 1px solid #c9cbd0;min-height:410px;">
                    <ul class="pricing-features" style="margin: 0;">
                        <li>
                            <img src="assets\img\Innovation_Lab\AI.png" alt="AI/ML Icon" style="width:20px; height:20px;">
                            AI/ML
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Fraud.png" alt="Fraud Analytics Icon" style="width:20px; height:20px;">
                            Fraud Analytics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\ComputerVison.png" alt="Computer Vision Icon" style="width:20px; height:20px;">
                            Computer Vision
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\5g.png" alt="5G Off-Grid Network Icon" style="width:20px; height:20px;">
                            5G Off-Grid Network
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\VidoAnalytic.png" alt="Video Analytics Icon" style="width:20px; height:20px;">
                            Video Analytics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\ocr.png" alt="OCR Icon" style="width:20px; height:20px;">
                            OCR / ICR / OMR
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\FaceReco.png" alt="Face Recognition Icon" style="width:20px; height:20px;">
                            Face Recognition
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\DocManagment.png" alt="DMS Icon" style="width:20px; height:20px;">
                            DMS
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\data-lake.png" alt="Data Lake Icon" style="width:20px; height:20px;">
                            Data Lake
                        </li>
                    </ul>
                </div>
            </div>
        
            <div class="col-lg-4 col-sm-12">
                <div class="single-pricing-table text-left"style="background-color: #f4f7fc;border: 1px solid #c9cbd0;min-height:410px;">
                    <ul class="pricing-features" style="margin: 0;">
                        <li>
                            <img src="assets\img\Innovation_Lab\KYC.png" alt="KYC Icon" style="width:20px; height:20px;">
                            Full Stack KYC
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\app.png" alt="Productivity Apps Icon" style="width:20px; height:20px;">
                            Productivity Apps & Services
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Digitaltwin.png" alt="Digital Twin Icon" style="width:20px; height:20px;">
                            Digital-Twin
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\bolt.png" alt="Humanoid Bot Icon" style="width:20px; height:20px;">
                            Humanoid Bot
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\IOT.png" alt="App Bolt-Ons Icon" style="width:20px; height:20px;">
                            App Bolt-Ons
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Buapp.png" alt="Business Apps Icon" style="width:20px; height:20px;">
                            Business Apps
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
</section>

<!-- Start Custom Developement -->

<section class="choose-area-two bg-f2f2f7" style="padding-top: 55px;">
    <div class="container">
        <h2 class=" text-center mb-3" >Custom Developement</h2>
        <p class="text-center" style="font-size: 20px;">We craft tailor-made software solutions designed to meet your business's unique needs.</p>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-content">
                
                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Tailored Solutions</h4>
                        <p>Customized applications built to align with your goals and processes.</p>
                    </div>
                    
                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Scalable Architecture</h4>
                        <p>Solutions designed for growth, adapting as your business expands.</p>
                    </div>
                    
                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Seamless Integration</h4>
                        <p>Smooth integration with existing systems, boosting efficiency without disruptions.</p>
                    </div>
                    

                  
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-image saas-banner-image animate-tb">
                    <img src="assets/img/Innovation_Lab/CustomDevelopement.png" alt="image" style="margin-top: 65px;">
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12">
                <div class="saas-banner-image animate-tb choose-image">
                     <img src="assets/img/Innovation_Lab/CustomDevelopement.png" alt="image" style="width: 90%">
                 </div>
             </div> -->
        </div>
    </div>
</section>


<!-- Start Pocket Office -->

<div class="bd-about-area pb-70" style="margin-top: 50px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="section-title with-line-text-with-white-color text-center" style="max-width: 925px;">
                    <h2 class=" text-center mb-3" >Pocket Office</h2>
                    <!-- <h3 class="sub-title" style="font-size: 25px;">Pocket Office</h3> -->
                    <p style="font-size: 20px;">Robust mobile app platform for seamless on-site and off-site task management. Seamlessly integrate third-party services & content.</p>
                </div>
            </div>
        </div>

        <div class="row align-items-start">
            <!-- Left Column: 3 Items -->
            <div class="col-lg-6 col-md-12">
                <div class="bd-about-content">
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 55px; margin-top: 6px;" src="assets\img\Innovation_Lab\Command Center.png" alt="Command Center">
                        </div>
                        <h4>Command Center</h4>
                        <p>Information hub for managing all field assets and resources in real-time.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 48px;" src="assets\img\Innovation_Lab\CRM.png" alt="mCRM">
                        </div>
                        <h4>mCRM</h4>
                        <p>Empower your customer engagements by scheduling visits, managing updates in real-time, and using a native CRM or integrating with your existing CRM system.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 48px;" src="assets\img\Innovation_Lab\complain.png" alt="Complaint Management">
                        </div>
                        <h4>Complaint Management</h4>
                        <p>A mobile-based service management platform with ticket-based tracking from start to closure, ideal for complaint and other service management.</p>
                    </div>
                </div>
            </div>

            <!-- Right Column: 3 Items -->
            <div class="col-lg-6 col-md-12">
                <div class="bd-about-content">
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 51px; margin-left: 11px;" src="assets\img\Innovation_Lab\attendance.png" alt="Attendance System">
                        </div>
                        <h4>Attendance System</h4>
                        <p>Employees can mark attendance on the go via mobile devices, preventing misuse.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 50px;" src="assets\img\Innovation_Lab\Employee Self Service.png" alt="">
                        </div>
                        <h4>Employee Self Service</h4>
                        <p>A mobile workflow-based employee self-service system for managing leave and other essentials. It supports employees and helps improve productivity.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="width: 55%" src="assets\img\Innovation_Lab\task.png" alt="">
                        </div>
                        <h4>Corporate Task Tracker</h4>
                        <p>A single platform that serves various organizational needs, helps manage individual initiatives and cross-functional corporate projects.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bd-about-area pb-70" style="margin-top: 50px;">
    <div class="container">
        <div class="row align-items-center">
           
                
                <h2 class=" text-center mb-3" >Constituent Technologies
                </h2>
            
                </div>
                <div class="row">
                    <div class="row">
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const1.png" width="50" alt="image">
                            </div>
                            <h6>Face-AI</h6>
                        </div></div>
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const2.png" width="50" alt="image">
                            </div>
                            <h6>GPS</h6>
                        </div></div>
                        <div class="col-md-2"> <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const3.png" width="50" alt="image">
                            </div>
                            <h6>Communication Platform</h6>
                        </div></div>
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const4.png" width="50" alt="image">
                            </div>
                            <h6>IOT sensors</h6>
                        </div></div>
                        <div class="col-md-2"> <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const5.png" width="50" alt="image">
                            </div>
                            <h6>Wearables</h6>
                        </div></div>
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const6.png" width="50" alt="image">
                            </div>
                            <h6>Workflow Engine</h6>
                        </div></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 col-md-6"></div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const7.png" width="50" alt="image">
                            </div>
                            <h6>Data Lake</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const8.png" width="50" alt="image">
                            </div>
                            <h6>Starlink Internet</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const9.png" width="50" alt="image">
                            </div>
                            <h6>Private 5G</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const10.png" width="50" alt="image">
                            </div>
                            <h6>Azure Integration</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                       
                    </div>
                    
               
        </div>
    </div>
</div>
<div class="bd-about-area pb-70" style="margin-top: 50px;">
    <div class="container">
        <div class="row align-items-center">
                <h3 class=" text-center mb-3" >Messaging & Communication Suite</h3>
                </div>
                <div class="row">
                    
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs1.png" width="50" alt="image">
                            </div>
                            <h6>Video Conf.
                                & Chat
                               </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs3.png" width="50" alt="image">
                            </div>
                            <h6>Enterprise Command & Control
                            </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs5.png" width="50" alt="image">
                            </div>
                            <h6>Broadcast Messages
                            </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs2.png" width="50" alt="image">
                            </div>
                            <h6>Push to talk (PTT) 
                                Walkie Talkie </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs4.png" width="50" alt="image">
                            </div>
                            <h6>Sub-Group Chats</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs5.png" width="50" alt="image">
                            </div>
                            <h6>Project Progress
                                Reporting</h6>
                        </div>
                    </div>
        </div>
        <div class="row align-items-center">
            <h3 class=" text-center mb-3" >Work Related</h3>
            </div>
            <div class="row">
                
                <div class="col-lg-2 col-md-6">
                   
                </div>
                <div class="col-lg-2 col-md-6">
                   
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="service-card-one bg-white center" style="padding: 0;">
                        <div class="icon">
                            <img src="assets\img\Innovation_Lab\mcs6.png" width="50" alt="image">
                        </div>
                        <h6>Task Management</h6>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="service-card-one bg-white center" style="padding: 0;">
                        <div class="icon">
                            <img src="assets\img\Innovation_Lab\mcs7.png" width="50" alt="image">
                        </div>
                        <h6>Site Inspections</h6>   
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                   
                </div>
                <div class="col-lg-2 col-md-6">
                   
                </div>
    </div>
    <div class="row align-items-center">
        <h3 class=" text-center mb-3" >Human Resource Related</h3>
        </div>
        <div class="row">
            
            <div class="col-lg-2 col-md-6">
                
            </div>
            <div class="col-lg-2 col-md-6">
                
            </div>
            <div class="col-lg-2 col-md-6">
                <div class="service-card-one bg-white center" style="padding: 0;">
                    <div class="icon">
                        <img src="assets\img\Innovation_Lab\mcs8.png" width="50" alt="image">
                    </div>
                    <h6>Attendance Marking</h6>
                </div>
            </div>
            <div class="col-lg-2 col-md-6">
                <div class="service-card-one bg-white center" style="padding: 0;">
                    <div class="icon">
                        <img src="assets\img\Innovation_Lab\mcs9.png" width="50" alt="image">
                    </div>
                    <h6>H.R.M.S 
                        Self-Service</h6>
                </div>
            </div>
            <div class="col-lg-2 col-md-6">
                
            </div>
            <div class="col-lg-2 col-md-6">
               
            </div>
</div>
<div class="row align-items-center">
    <h3 class=" text-center mb-3" >Workforce Health & Safety Related</h3>
    </div>
    <div class="row">
        
        <div class="col-lg-2 col-md-6">
           
        </div>
        <div class="col-lg-2 col-md-6">
           
        </div>
        <div class="col-lg-2 col-md-6">
            <div class="service-card-one bg-white center" style="padding: 0;">
                <div class="icon">
                    <img src="assets\img\Innovation_Lab\mcs10.png" width="50" alt="image">
                </div>
                <h6>Safety Interfaces</h6>
            </div>
        </div>
        <div class="col-lg-2 col-md-6">
            <div class="service-card-one bg-white center" style="padding: 0;">
                <div class="icon">
                    <img src="assets\img\Innovation_Lab\mcs11.png" width="50" alt="image">
                </div>
                <h6>Health  Monitors
                </h6>
            </div>
        </div>
        <div class="col-lg-2 col-md-6">
           
        </div>
        <div class="col-lg-2 col-md-6">
           
        </div>
</div>
    </div>
</div>

