


<app-navbar></app-navbar>


<!-- Start Main Banner Area -->
<div class="saas-banner-two hero-banner banner-bg2 ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-50">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1><span class="highlight">Driving Excellence in</span> AI/ML,  Computer Vision <span class="highlight">and</span> Cybersecurity</h1>
                            <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements.</p>
                            
                            <div class="banner-btn">
                              
    
                                <a class="default-btn-two  burger-menu">
                                    Try It Now <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">

                        
                       <div class="saas-banner-image animate-tb">
                            <img src="assets/img/Asset 1.svg" alt="image">

                            <div class="solution-video d-none">
                                <a href="https://www.youtube-nocookie.com/embed/jzYxbpId2sI?start=9" class="video-btn popup-youtube">
                                    <i class="flaticon-play-button"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Shape Images -->
 
</div>
<!-- End Main Banner Area -->


<!-- Start Overview Area -->
<section class="overview-area ptb-100">
    <div class="container">

       

        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/digivision-logo.png" class="Digivision-logo" alt="Digivision">
                    <img src="assets/img/services/it-service5.png" class="opacity-25" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <kbd class="bg-maincolor">DIGIVISION.AI</kbd>
                    <h2 class="mt-2">An Intelligent Digital Eye</h2>
                    <p>OCR & Image-based solutions which provides a quantum leap in operational efficiency. Multiple “Use-cases” for Financial Services, Telecom, Education sectors etc.</p>


                    <div class="stats-area pt-4">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="stats-fun-fact-box text-left">
                                       
                                        <h1 class="highlight">1.32 bn+</h1>
                                        <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                        <p>Document Verified</p>
                                    </div>
                                </div>
                    
                                <div class="col-lg-4 col-sm-6">
                                    <div class="stats-fun-fact-box text-left">
                                       
                                        <h1 class="highlight">1.60 bn+</h1>
                                        <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                        <p>Aadhar  Masking</p>
                                    </div>
                                </div>
                    
                                <div class="col-lg-4 col-sm-6">
                                    <div class="stats-fun-fact-box text-left">
                                      
                                        <h1 class="highlight">8.3 mn+</h1>
                                        <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                        <p>Video KYCs</p>
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                    </div> 
                    
                  
                    <div class="rm-btn">
                      <a routerLink="/Digivision" class="default-btn-two">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Why Choose Us -->
<section class="choose-area-two ptb-100 bg-f2f2f7">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <kbd class="bg-maincolor">NextGen DMS - DIGIDRIVE.AI</kbd>
                        <h2>Making paradigmatic shift in Document - Management  </h2>
                        <p>Powered with potential & promise to streamline the content &  Document - Management .</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i> 
                        <h4>AI-based Content Extraction & Metadata Creation</h4>
                        <p>Unlock insights and efficiency with AI-driven content extraction and accurate metadata generation.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Large Object Archive - Audio & Video</h4>
                        <p>Effortlessly manage and access large audio and video files with our scalable archive solution.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Supremely Efficient Compression</h4>
                        <p>Optimize storage space with our proprietary compression technology, reducing file sizes without quality loss.</p>
                    </div>

                    <div class="choose-btn">
                        <a class="default-btn-two" routerLink="/Digidrive">
                            Discover More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <!-- <img src="assets/img/DigiDrive.png"  class="Digivision-logo" alt="image"> -->
                    <img src="assets/img/services/it-service3.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us -->


<!-- Trucheck  -->

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <!-- <img src="assets/img/truecheck_home.jpg" class="Digivision-logo" alt="Digivision"> -->
                    <img src="assets/img/trucheck_home.png"  alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <kbd class="bg-maincolor">Truecheck.AI</kbd>
                    <h1 style="font-size: 50px; color: red; margin: 0;">Identity Verification & Fraud Prevention Platform</h1>	
                    <!-- <h3></h3> -->
                    <h3 class="mt-2">Onboard Everyone but Not Anyone...!!</h3>
                    <p>Protect your digital environment with built-in spam prevention and auto-classification tools, which simplify form completion and ensure data integrity.
                        Employ advanced technologies like AI-driven tamper detection, Customer Onboarding, Auto underwriting, face matching, Signature AI, and cross-document verification to enhance security measures and user unauthorized access attempts.</p>
                    <div class="stats-area pt-4">
                        <div class="container">
                            <div class="row justify-content-left">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="stats-fun-fact-box text-left">
                                       
                                        <h1 class="highlight">200 Mn+</h1>
                                        <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                        <p>Identity Verified</p>
                                    </div>
                                </div>
                    
                                <!-- <div class="col-lg-4 col-sm-6">
                                    <div class="stats-fun-fact-box text-left">
                                       
                                        <h1 class="highlight">1.60 bn+</h1>
                                        <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                        <p>Aadhar  Masking</p>
                                    </div>
                                </div>
                    
                                <div class="col-lg-4 col-sm-6">
                                    <div class="stats-fun-fact-box text-left">
                                      
                                        <h1 class="highlight">8.3 mn+</h1>
                                        <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                        <p>Video KYCs</p>
                                    </div>
                                </div> -->
                    
                            </div>
                        </div>
                    </div> 
                    
                  
                    <!-- <div class="rm-btn">
                      <a href="https://www.truecheck.ai/" class="default-btn-two" target="_blank">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                    <div class="rm-btn">
                        <a routerLink="/TrueCheck" class="default-btn-two">
                              Read More 
                              <span></span>
                          </a>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Check -->


<!-- Pricing Area -->
<section class="pricing-area-two pt-100 pb-70 bg-black ">
    <div class="container">
        <div class="section-title"><P class="text-center mb-2"><img src="assets/img/secureNexusicon.png" width="160" alt="Secure Nexus"></P>
            <h2 class="text-white">Secure Nexus</h2>
            <p class="text-white"> 
                "SECURE THE DIGITAL NEXUS OF FORCES."</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6 col-sm-12">
                <div class="single-pricing-table text-left">
                    <div class="pricing-header">
                        <div class="icon float-start">
                            <img src="assets/img/icon2.png" alt="Icon">
                        </div>
                       
                        </div>
                        <p class="mb-0"><kbd class="bg-maincolor">Information Security Services</kbd></p>
                    <h3>
                        Securing your Nexus of Forces with precision and expertise
                    </h3>
                    <P> Experts in Application, Infrastructure, and Cloud Security assessments. Protecting your digital assets with precision and expertise.</P>
                   
                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Realtime Attack Surface Monitoring
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Advanced Application and API Security Testing
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Cloud Security Assessment
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Infrastructure Security Assessment
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Red Teaming
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Cyber Security Advisory
                        </li>
                       
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn-two  bg-black" routerLink="/SecureNexus-ISS">
                            Read More
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-12">
                <div class="single-pricing-table text-left">
                    <div class="pricing-header">
                        <div class="icon  float-start">
                            <img src="assets/img/icon1.png" alt="Icon">
                        </div>
                        
                    </div>
                   <p class="mb-0"><kbd class="bg-maincolor">Governance Risk & Compliance</kbd></p> 
                    <h3>
                        Empower next-generation governance,  risk &  compliance.
                    </h3>
<p>A CXO friendly tool to manage IT security, Governance & Compliance requirements. Fully customization with inbuilt Builder & dynamic workflows.</p>
                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Full Attack Surface Management
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Software Composition Analysis 
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Business Continuity Management
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Vendor Risk Management
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Exception Management
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Secrets Management
                        </li>
                    
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn-two bg-black" routerLink="/SecureNexus-GRC">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

        
        </div>
    </div>

    <!-- Shape Images -->
    <!--  <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div> -->
</section>
<!-- End Pricing Area -->


<!-- Start Featured Services Area -->
<section class="seo-featured-services pt-100 pb-70 gray-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-us-content text-start">
                    <span class="sub-title"> Use Cases and Solutions</span>
                    <h3>Explore our portfolio of <span> Industry-Driven</span> use cases  &  Solutions <!--to witness the transformative power of our solutions.--></h3>
                    <p>We work hand-in-hand with industry to help redefine the possibilities and potential of digital engagements.</p>
</div>
            </div>

            <div class="col-lg-6">
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <img src="assets/img/bank.svg" width="50" alt="image">
                                
                            </div>
                            <h3>
                                <a routerLink="/Banking">Banking & Finance</a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <img src="assets/img/insurance.svg" width="50" alt="image">
                            </div>
                            <h3>
                                <a routerLink="/Insurance">Insurance</a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <img src="assets/img/healthcare.svg" width="50" alt="image">
                            </div>
                            <h3>
                                <a routerLink="/Healthcare">Healthcare </a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">  
                                <img src="assets/img/tele-presence.svg" width="50" alt="image">  
                            </div>
                            <h3>
                                <a routerLink="/Tele-com">Tele-Communication</a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->


<!-- About Us -->
<section class="choose-area-two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/aboutus.svg" class="opacity-75" alt="image">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <span class="sub-title">About X-BIZ TECHVENTURES</span>
                        <h2>We Innovate, <br /><span class="highlight">Incubate & Invest</span></h2>

                        <p>Xbiz techventures private limited are Information Management professionals who have been at leadership roles as <b class="text-black">CEO / CIO / CXO with Global organizations in FMCG, Retail, Financial Services, Private Security, Renewable Energy sectors</b>.</p> <p>xbiz aims to create an eco-system of socially relevant products & services that touch people, that are unique, that address unstated needs, which are easy to use. xbiz explores un-chartered waters and fosters a spirit of Adventure in the Ventures.</p>
                    </div>

                    <div class="choose-btn">
                        <a class="default-btn-two" routerLink="/AboutUs">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

          
        </div>
    </div>
</section>
<!-- End About Us -->

<!-- Lets work Area -->
<section class="subscribe-area bg-f2f2f7">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center subscribe-content">
                <div class="col-lg-12">
                   <img src="assets/img/iso.png" class="iso-img">
                   
                </div>

            
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->

<!-- Lets work Area -->
<section class="subscribe-area bg-F4F7FC d-none">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center subscribe-content">
                <div class="col-lg-6">
                    <h2>Join Our Team</h2>
                    <span class="sub-title">Share your profile to be a part of innovative tech team.</span>
               
                    <form class="newsletter-form" data-toggle="validator">
                        <input type="file" class="input-newsletter form-control pt-4" placeholder="Upload your resume" name="EMAIL" required autocomplete="off">
    
                        <button type="submit">Send Now</button>
                        <div id="validator-newsletter" class="form-result"></div>
                    </form>
                   
                </div>

                <div class="col-lg-6">
                    <h2>Keep in Touch</h2>
                    <span class="sub-title">We will send you our latest insights and updates</span>
               
                    <form class="newsletter-form" data-toggle="validator">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
    
                        <button type="submit">Subscribe</button>
                        <div id="validator-newsletter" class="form-result"></div>
                    </form>
                   
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->